import './App.css';
import React, { useCallback, useState, useEffect }  from 'react';
import {Unity, useUnityContext } from "react-unity-webgl";
//import { Web3AuthRequest } from './Models/Web3AuthRequest';
import LoadingBar from 'react-top-loading-bar'
//import {LoginButtonEmpty, LoginButtonGuest, LoginButtonConnetWallet} from './LoginButtons.js'
//import {Button, Stack, Text, Center} from '@mantine/core'
import Web3 from 'web3'
//import ethUtil from 'ethereumjs-util'
//import WalletConnectProvider from "@walletconnect/web3-provider";
//import MetnaLogo from './images/metnaLogo.png'
import axios from 'axios'

let ethUtil = require('ethereumjs-util');


function App() {
  const { unityProvider, requestPointerLock,  sendMessage, isLoaded, loadingProgression, addEventListener, removeEventListener } = 
  useUnityContext({
    loaderUrl: "Build/mainMetaverse.loader.js",
    dataUrl: "Build/mainMetaverse.data.gz",
    frameworkUrl: "Build/mainMetaverse.framework.js.gz",
    codeUrl: "Build/mainMetaverse.wasm.gz",
  });

  // useEffect(() => {
  //   document.addEventListener("click", requestPointerLock);
  //   return () => {
  //     document.removeEventListener("click", requestPointerLock);
  //   };
  // }, [requestPointerLock]);

  // setConnectStatus(string "bearerToken,ethAddress") returns the JWT and EthAddress concatinated with ','
  const [authResult, setConnectStatus] = useState();

  useEffect(() => {
    AuthenticationComplete(); 
    return () => {
    }
  }, [authResult])

  function AuthenticationComplete(){
    //var returnStr = bearerToken;
    //var bufferSize = lengthBytesUTF8(returnStr) + 1;
    //var buffer = _malloc(bufferSize);
    //stringToUTF8(returnStr, buffer, bufferSize);
    sendMessage("MainMenuManager", "AuthenticationComplete", authResult);
  }

  // We'll round the loading progression to a whole number to represent the
  // percentage of the Unity Application that has loaded.
  const loadingPercentage = Math.round(loadingProgression * 100);

  // Variables for react-top-loading-bar, we're only using setProgress(0) in <LoadingBar> tag 
  // passing in loadingPercentage given by unity context
  const [progress, setProgress] = useState(0);
  // extra var for controlling the overlay
  const [showOverlay, setShowOverlay] = useState(true);
  //const [web3, setWeb3] = useState();

  // This is where the Ethereum Address is stored.
  var coinbase = null;
  var web3 = null;

  const handleConnectButtonClicked = useCallback(async () => {
    //Check if Metamask (or Web3 instance) exists and use it if so, if not use WalletConnect
    if(window.ethereum){
      //Metamask is installed proceed to connect, generate message, sign, then send all data back to Unity to begin authentication
      await window.ethereum.request({method:'eth_requestAccounts'});
      //setWeb3(new Web3(window.ethereum));
      web3 = new Web3(window.ethereum);
      //coinbase = await web3.eth.getCoinbase;
      const wcAccounts = await web3.eth.getAccounts();
      coinbase = wcAccounts[0];

      //await Authenticate();
    }
    else{
      // //Metamask not found, instantiate using walletconenct and set web3 provider that way
      // const provider = new WalletConnectProvider({
      //   infuraId: "659c21b634ac469ca057d752f6603bfe", // Required
      // });
      // //Enable session (triggers QR Code modal)
      // await provider.enable(); 
      // //Create Web3js provider
      // //setWeb3(new Web3(provider));
      // web3 = new Web3(provider);
      // const wcAccounts = await web3.eth.getAccounts();
      // coinbase = wcAccounts[0];
      // //await Authenticate();
    }

    //If no JWT or ExpiredJWT, get new nonce, sign message and send to Authentication MetnaAPI
    var authResult = window.localStorage.getItem('bearer');
    if(!authResult){
        const result = await axios.get("https://api.metna.app/Authentication/connect/" + coinbase);
        await GenerateMessageandSign(await result.data);
    }
    else{
      console.log("bearer already exists!");
      //AuthenticationComplete();
      setConnectStatus(authResult);
    }

  }, []);

  async function Authenticate(){
    //Check if Metamask (or Web3 instance) exists and use it if so, if not use WalletConnect
    if(window.ethereum){
      //Metamask is installed proceed to connect, generate message, sign, then send all data back to Unity to begin authentication
      await window.ethereum.request({method:'eth_requestAccounts'});
      //setWeb3(new Web3(window.ethereum));
      web3 = new Web3(window.ethereum);
      //coinbase = await web3.eth.getCoinbase;
      const wcAccounts = await web3.eth.getAccounts();
      coinbase = wcAccounts[0];
      //await Authenticate();
    }
    else{
      // //Metamask not found, instantiate using walletconenct and set web3 provider that way
      // const provider = new WalletConnectProvider({
      //   infuraId: "659c21b634ac469ca057d752f6603bfe", // Required
      // });
      // //Enable session (triggers QR Code modal)
      // await provider.enable(); 
      // //Create Web3js provider
      // //setWeb3(new Web3(provider));
      // web3 = new Web3(provider);
      // const wcAccounts = await web3.eth.getAccounts();
      // coinbase = wcAccounts[0];
      // //await Authenticate();
    }

    //If no JWT or ExpiredJWT, get new nonce, sign message and send to Authentication MetnaAPI
    var authResult = window.localStorage.getItem('bearer');
    if(!authResult){
        const result = await axios.get("https://api.metna.app/Authentication/connect/" + coinbase);
        await GenerateMessageandSign(await result.data);
    }
    else{
      console.log("bearer already exists!")
      setConnectStatus(authResult);
    }
  }

  const GenerateMessageandSign = async (nonce) => {
    console.log("GenerateMessageAndSign called with nonce: " + nonce)

    var plain = "Signing this message will authenticate your account with Metna services. " + 
    "This does not incurr any fees or conduct a transaction on the blockchain.\n\n" + nonce
    var plainForHash = "\x19Ethereum Signed Message:\n" + plain.length + plain;
    var bufferForHash = ethUtil.bufferToHex(new Buffer.from(plainForHash, 'utf8'));
    //var message = ethUtil.bufferToHex(new Buffer.from(plain, 'utf8'));
    //const keccakString = ethUtil.keccak256("\x19Ethereum Signed Message:\n" + plain.length + plain)
    var hash = ethUtil.bufferToHex(bufferForHash);
    console.log("Hash: \n" + hash)
    //var from = coinbase;

    console.log("Plain Hex: \n" + web3.utils.toHex(plain));
  
    console.log("Eth address: " + coinbase)

    web3.eth.personal.sign(plain, web3.utils.toChecksumAddress(coinbase), async function(err, result){
      if(!result){
        console.log("Result emtpy. Signature failed or canceled.")
        console.log(err)
        setConnectStatus("");
      }
      else{
        console.log('Successfull signature: ' + result);
        const response = await axios.get("https://api.metna.app/Authentication/login/" + coinbase + "/" + result);
        console.log("Bearer token: \n" + response.data);
        window.localStorage.setItem("bearer", response.data);
        //AuthenticationComplete();
        setConnectStatus(response.data);
      }   
    })
  };

  const GetBearerToken = () => {

  };

  useEffect(() => {
    addEventListener("ConnectButtonClicked", handleConnectButtonClicked);
    return () => {
      removeEventListener("ConnectButtonClicked", handleConnectButtonClicked);
    };
  }, [addEventListener, removeEventListener, handleConnectButtonClicked]);

  return (
    <div className="container h-full bg-barscene overflow-hidden">

      <LoadingBar
        color='#f11946'
        progress={loadingPercentage}
        onLoaderFinished={() => setProgress(0)}
      />
      
      <Unity className="unity" unityProvider={unityProvider} />
    </div>
  );
}

export default App;
